import React, { useState, useRef, useEffect } from 'react';
import Select from "react-select";
import JoditEditor from 'jodit-react';

const options = (props) => {
    const optiondata = [];
    props.datasource && props.datasource.masterdata.map((item) => {
        optiondata.push({ value: item.id, label: item.type_name })
    })

    return optiondata
};

const FormElements = (props) => {
    console.log("props",props);
    const editor = useRef(null);
    const [content, setContent] = useState('');
    console.log("readOnly",props.readOnly)

    const [config, setConfig] = useState({
        placeholder: props.placeholder || '',        
        readonly: props.readOnly ? true : false,
        hidePoweredByJodit: true,
        toolbar: props.toolBar ? false : true,
        statusbar: false,
        safeMode: true,
        minHeight: props.minHeight ? props.minHeight : 200,
    });

    switch (props.type) {
        case 'select':
            return (
                <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">{props.label}</label>
                    <div className="col-sm-9">
                        <select
                            name={props.name}
                            onChange={props.onChange}
                            className="form-control"
                        >
                            <option key={'0'} value={'0'}>Pilih Tipe</option>
                            {
                                options(props).map((item, key) => {
                                    if (parseInt(item.value) === parseInt(props.value)) {
                                        return <option key={key} value={item.value} selected={'selected'}>{item.label}</option>
                                    } else {
                                        return <option key={key} value={item.value}>{item.label}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
            )
        case 'textarea':
            return (
                <div className="mb-3 row">
                    <div className="col-sm-12">
                        <JoditEditor
                            ref={editor}
                            value={props?.value || content}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                            id={props.name}
                            name={props.name}
                        />
                    </div>
                </div>
            )
        default:
            return (
                <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">{props.label}</label>
                    <div className="col-sm-9">
                        <input
                            name={props.name}
                            type={props.type}
                            className="form-control"
                            placeholder={props.label}
                            onChange={props.onChange}
                            defaultValue={props.value}
                        />
                    </div>
                </div>
            )
    }
}

export default FormElements
