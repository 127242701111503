import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import FormElementsVertical from "../../Forms/FormElementsVerical";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";


const JadwalEditPage = (props) => {
  const [data, setData] = useState([])  
  const [field, setField] = useState([])  
  const [inputs, setInputs] = useState({});
  const { id } = useParams();

  const selectRef = useRef();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }  
  const handleSave = (e) => {
    e.preventDefault();
    const allInputs = document.querySelectorAll('input[name],select[name],textarea[name],[type="hidden"][name]');
    const inputsData = {};
    let isBlank = false;

    allInputs.forEach(input => {
      console.log(input);
      if (input.type === 'hidden') {
        if (!inputsData[input.name]) {
            inputsData[input.name] = [];
        }
        inputsData[input.name].push(input.value);
      } else {
        if (input.value.trim() === '') {
          isBlank = true;
        }
        inputsData[input.name] = input.value;
      }
    });

    if (isBlank) {
      swal({
        title: "Error",
        text: "Lengkapi data terlebih dahulu",
        icon: "error",
      });
      return;
    }

    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        UseAPI(`jadwal/${id}`,inputsData,'POST').then((resp)=>{
          console.log(resp);
          Swal.fire({
            title: resp.status,
            text: resp.pesan,
            icon: resp.status
          })
        });
      }
    })    
    
  }
	useEffect(() => {
		const fetchData = async () => {
			await UseAPI(`jadwal/${id}`,{},'GET').then((resp)=>{
				setData(resp.data)        
				setField(resp.field)
			})
		};
		fetchData();
	}, []);

  return (
    <Fragment>
      <PageTitle
        motherMenu="Jadwal"
        activeMenu="Tambah"
        pageContent="Jadwal"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                  {field.map((item, index) => (
                    <FormElementsVertical   
                        key={index}
                        name={item.field}
                        type={item.type}
                        label={item.label}
                        masterdata={item.masterdata}     
                        isMulti={false}
                        value={item.type === 'select' ? item.masterdata.find((thisdata) => thisdata.value === data[item.field]) : data[item.field]}
                    />
                  ))}
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default JadwalEditPage;
