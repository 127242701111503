import React, { useContext, useEffect, useState } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Charts
// import ChartJs from "./components/charts/Chartjs";


import { ThemeContext } from "../context/ThemeContext";

import AngketUser from "./components/AngketUser/AngketUser";
import AngketStart from "./components/AngketUser/AngketStart";

// master
import JadwalPage from "./components/Master/Jadwal/Jadwal";
import MahasiswaPage from "./components/Master/Mahasiswa/Mahasiswa";
import DosenPage from "./components/Master/Dosen/Dosen";
import MataKuliah from "./components/AngketUser/MataKuliah";
import AngketStartProfesi from "./components/AngketUser/AngketStartProfesi";
import JadwalTambahPage from "./components/Master/Jadwal/JadwalTambah";
import JadwalEditPage from "./components/Master/Jadwal/JadwalEdit";
import DosenEditPage from "./components/Master/Dosen/DosenEdit";
import DosenTambahPage from "./components/Master/Dosen/DosenTambah";
import AngketAdmin from "./components/AngketAdmin/AngketAdmin";
import { UseAPI } from "../services/UseAPI";
import AngketAdminDashboard from "./components/AngketAdmin/AngketAdminDashboard";
import AngketAdminMahasiswa from "./components/AngketAdmin/AngketAdminMahasiswa";


const Markup = () => {
  const [user, setUser] = useState({});
  const { menuToggle } = useContext(ThemeContext);
  const cek_token = async() => {
    try {
      const response = await UseAPI('authenticated', {}, 'GET');
      setUser(response);
    } catch (error) {
      console.error("Error checking token:", error);
    }
  };
  useEffect(() => {
    cek_token();
  }, []);
  const routes = [];
  if (user?.id_tipe_akun === 1) {
    routes.push(
      /// Dashboard
      { url: "", component: Home },
      { url: "dashboard", component: Home }, 
  
      /// Angket User
      { url: "angket", component: AngketUser },
      { url: "angket/:id", component: AngketStart },
      { url: "angket/:id_angket/matakuliah", component: MataKuliah },
      { url: "angket/:id_angket/jadwal/:id_jadwal/isi", component: AngketStartProfesi }
    );
  } else {
    routes.push(
      /// Dashboard
      { url: "", component: Home },
      { url: "dashboard", component: Home }, 
  
      /// Angket User
      { url: "angket", component: AngketUser },
      { url: "angket/:id", component: AngketStart },
      { url: "angket/:id_angket/matakuliah", component: MataKuliah },
      { url: "angket/:id_angket/jadwal/:id_jadwal/isi", component: AngketStartProfesi },
  
      /// Angket Admin
      { url: "angket_admin", component: AngketAdminDashboard },
      { url: "angket_admin/mahasiswa/:id_angket", component: AngketAdminMahasiswa },
      // { url: "angket_admin/:id", component: AngketAdminD },
  
      /// Master
      { url: "jadwal", component: JadwalPage },
      { url: "jadwal/edit/:id", component: JadwalEditPage },
      { url: "jadwal/tambah", component: JadwalTambahPage },
      { url: "dosen", component: DosenPage },
      { url: "dosen/edit/:id", component: DosenEditPage },
      { url: "dosen/tambah", component: DosenTambahPage },
      { url: "mahasiswa", component: MahasiswaPage },
      { url: "master/angket", component: AngketAdmin },
      { url: "master/angket/clone/:id_angket", component: AngketAdmin },
      { url: "master/angket/baru/:id_angket", component: AngketAdmin },
      { url: "master/angket/edit/:id_angket", component: AngketAdmin },
      { url: "master/angket", component: AngketAdmin }
    );
  }

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  // console.log(path);

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 250 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>      
    </>
  );
};

export default Markup;
