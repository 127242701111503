import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import FormElementsVertical from "../../Forms/FormElementsVerical";
import Swal from "sweetalert2";


const JadwalTambahPage = () => {
  const [data, setData] = useState([])  
  const [inputs, setInputs] = useState({});

  const selectRef = useRef();

  const handleSave = (e) => {
    e.preventDefault();
    const allInputs = document.querySelectorAll('input[name],select[name],textarea[name],[type="hidden"][name]');
    const inputsData = {};
    let isBlank = false;

    allInputs.forEach(input => {
      console.log(input);
      if (input.type === 'hidden') {
        if (!inputsData[input.name]) {
            inputsData[input.name] = [];
        }
        inputsData[input.name].push(input.value);
      } else {
        if (input.value.trim() === '') {
          isBlank = true;
        }
        inputsData[input.name] = input.value;
      }
    });

    if (isBlank) {
      Swal.fire({
        title: "Error",
        text: "Lengkapi data terlebih dahulu",
        icon: "error",
      });
      return;
    }

    Swal.fire({
      title: "Anda yakin?",
      text: "Data yang disimpan tidak dapat diubah",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        UseAPI('jadwal', inputsData, 'POST').then((resp) => {
          console.log(resp);
          Swal.fire({
            icon: resp.status,
            title: resp.status,
            text: resp.pesan
          })
        });
      }
    })
    
  }
	useEffect(() => {
		const fetchData = async () => {
			await UseAPI('jadwal/postdata',{},'GET').then((resp)=>{
				setData(resp)
			})
		};
		fetchData();
	}, []);

  return (
    <Fragment>
      <PageTitle
        motherMenu="Jadwal"
        activeMenu="Tambah"
        pageContent="Jadwal"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tambah</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                  {data.map((item, index) => (
                    <FormElementsVertical   
                        key={index}
                        id={`${item.field}_${index}`}
                        name={item.field}
                        type={item.type}
                        label={item.label}
                        masterdata={item.masterdata}     
                        isMulti={item.masterdata && item.masterdata[0].is_multi===1 ? true : false}
                    />
                  ))}
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default JadwalTambahPage;
