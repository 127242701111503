import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { UseAPI } from "../../../services/UseAPI";
import FormElements from "./FormElements";
import FormElementsVertical from "../Forms/FormElementsVerical";

const AngketStart = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState([]);
  const [selectedDosen, setSelectedDosen] = useState([]);
  const [saran, setSaran] = useState('');
  const history = useHistory();

    const handleEditorChange = (event) => {
        if (event) {
            const name = event.target.id;
            const value = event.target.getContent();    
            setSaran(values => ({...values, [name]: value}))
        } 
    };  

  const handleSave = (e) => {
    e.preventDefault();

    const allInputs = document.querySelectorAll('input[name],select[name],textarea[name],[type="hidden"][name]');
    const inputsData = {};
    let isBlank = false;

    allInputs.forEach(input => {
      console.log(input);
      if (input.type === 'hidden') {
        if (!inputsData[input.name]) {
            inputsData[input.name] = [];
        }
        inputsData[input.name].push(input.value);
      } else {
        if (input.value.trim() === '') {
          isBlank = true;
        }
        inputsData[input.name] = input.value;
      }
    });

    if (isBlank) {
      Swal.fire({
        title: "Error",
        text: "Lengkapi data terlebih dahulu",
        icon: "error",
      });
      return;
    }

    // console.log(selectedDosen);
    console.log(inputsData);
    console.log(selectedRadio);
    // if (saran.saran === '') {
    //   Swal.fire({
    //     title:'Proses',
    //     text:'Gagal, lengkapi saran'
    //   })
    // }
    if (inputsData.saran.trim() !== '') {
      Swal.fire({
        title: 'Proses...',
        text: 'Sedang mengirim data',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          UseAPI(
            "angket/insert_jawaban",
            { id_angket: id, answer: JSON.stringify(selectedRadio), dosen: selectedDosen.length > 0 ? selectedDosen[0]['nidn'] : '', saran: inputsData.saran},
            "POST"
          ).then((resp) => {
            Swal.close();
            if (resp.status === 'error') {
              Swal.fire({
                text: resp.message,
                icon: resp.status
              });            
            } else {
              Swal.fire({
                text: resp.message,
                icon: resp.status
              }).then((result) => {
                if (result.isConfirmed) {
                  history.go(0);

                }
              });          
            }
            // setData(resp.data.soal);
            // setSelectedRadio(resp.data.soal.answer);
          })
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Saran tidak boleh kosong!',
      });
    }
  };

  const handleChange = (event, index) => {
    const updatedData = [...selectedRadio];
    updatedData[index] = { name: event.target.name, val: event.target.value };
    // console.log(updatedData);
    setSelectedRadio(updatedData);
  };

  const handleDosen = (e) => {
    setSelectedDosen([{ [e.target.name]: e.target.value }]);
  }
  useEffect(() => {
    UseAPI(`angket/${id}`, {}, "GET").then((resp) => {
      console.log("resp",resp);
      // if (resp.data && resp.data.jawaban.length >= 3) {
      //   history.push("/angket"); 
      // }
      setData(resp.data);
    });
  },[id]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
        <form onSubmit={handleSave}>
         
          <div className="card">
            <div className="card-header">
              <span>
                <h3>
                  Silahkan memilih salah satu jawaban pada setiap nomor yang
                  menurut Anda sesuai, dan akhiri dengan menekan tombol simpan
                  dibawah soal ini{" "}
                </h3>
              </span>
            </div>
            <div className="card-body mt-3">

              
              <div className="basic-form">                
                  {data.angket && data.angket.multi > 0 ? (
                    <Form.Select aria-label="Nama Dosen" name="nidn" onChange={(e) => handleDosen(e)}>
                      <option value="">Silahkan pilih Dosen</option>
                      {data.dosen.map((item, key) => (
                        <option key={key} value={item.nidn}>{item.nama}</option>
                      ))}
                    </Form.Select>
                  ):''}
                

                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table display responsive nowrap w-100 dataTable dataTables_wrapper"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr>
                          <th>Pernyataan</th>
                          {data.pilihan &&
                            data.pilihan.map((pilihan, pIndex) => (
                              <th key={pIndex}>
                                {pilihan.nama_angket_pilihan_jawaban}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.base_table && data.base_table === 'angket' &&
                        data.soal && data.soal.map((row, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#797777" : "white",
                            }}
                          >
                            <td>{row.nama_angket_soal}</td>
                            {row.pilihan.map((val, pIndex) => (
                              <td key={pIndex}>
                                <input
                                  type="radio"
                                  name={`option-${row.id_angket_soal}`}
                                  value={val.id_angket_pilihan_jawaban}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </td>
                            ))}
                          </tr>
                        ))
                        }
                        {data.base_table && data.base_table === 'tbl_survey' &&
                        data.soal && data.soal.map((row, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#797777" : "white",
                            }}
                          >
                            <td>{row.pertanyaan}</td>
                            {row.pilihan.map((val, pIndex) => (
                              <td key={pIndex}>
                                <input
                                  type="radio"
                                  name={`option-${row.id}`}
                                  value={val.id_angket_pilihan_jawaban}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </td>
                            ))}
                          </tr>
                        ))
                        }
                        <tr>
                          <td colSpan={5}>
                            <p>Tuliskan saran Anda dibawah ini  (Wajib)</p>
                            {/* <textarea></textarea> */}
                            
                            <FormElements label='Saran (wajib)' type='textarea' name='saran' value={saran} onChange={(e)=>setSaran(e.target)} />
                            {/* <FormElementsVertical 
                                name={'saran'}
                                label={'Saran'}
                                type={'textarea'}
                                datasource={saran}                            
                              /> */}

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
            <div className="card-footer">
              <button className="btn btn-sm btn-primary" type="submit">
                <FontAwesomeIcon icon={faCheck} className="pe-2" />
                Simpan
              </button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AngketStart;
