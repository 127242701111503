import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { UseAPI } from "../../../services/UseAPI";
import FormElements from "./FormElements";
import FormElementsVertical from "../Forms/FormElementsVerical";

const AngketStartProfesi = () => {
  const { id_angket, id_jadwal } = useParams();
  const [data, setData] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState([]);
  const [selectedDosen, setSelectedDosen] = useState([]);
  const [saran, setSaran] = useState('');
  const history = useHistory();

    const handleEditorChange = (event) => {
      console.log(event);
        if (event) {
            const name = event.target.id;
            const value = event.target.getContent();    
            setSaran(values => ({...values, [name]: value}))
        } 
    };  

    const handleSave = (e) => {
      // Preventing the default form submission behavior if needed
      e.preventDefault();

      const allInputs = document.querySelectorAll('input, select, textarea');
      const inputsData = {};
      allInputs.forEach(input => {
          inputsData[input.name] = input.value;
      });
      console.log(inputsData);      
  
      // Accessing the collected radio values from the state
      if (inputsData.saran.trim() !== '') {
          Swal.fire({
              title: 'Proses...',
              text: 'Sedang mengirim data',
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading();
                  UseAPI(
                      "angket/insert_jawaban",
                      {
                          id_angket: id_angket,
                          answer: JSON.stringify(selectedRadio),
                          dosen: selectedDosen.length > 0 ? selectedDosen[0]['nidn'] : '',
                          saran: inputsData.saran,
                          kode_matakuliah: data.dosen[0].kode_mk,
                          id_jadwal:id_jadwal
                      },
                      "POST"
                  ).then((resp) => {
                      // Handling response after the API call
                      Swal.close();
                      if (resp.status === 'error') {
                          Swal.fire({
                              text: resp.message,
                              icon: resp.status
                          });            
                      } else {
                          Swal.fire({
                              text: resp.message,
                              icon: resp.status
                          }).then((result) => {
                              if (result.isConfirmed) {
                                  history.go(0);
                              }
                          });          
                      }
                  });
              }
          });
      } else {
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Saran tidak boleh kosong!',
          });
      }
  };

  const handleChange = (event, index) => {
    const updatedData = [...selectedRadio];
    updatedData[index] = { name: event.target.name, val: event.target.value };
    console.log(updatedData);
    setSelectedRadio(updatedData);
  };

  const handleDosen = (e) => {
    setSelectedDosen([{ [e.target.name]: e.target.value }]);
  }
  useEffect(async () => {
    try {
      const resp = await UseAPI(`angket/profesi_dokter/${id_angket}/${id_jadwal}`, {}, "GET");
      console.log("resp", resp);
      if (resp.data.dosen.length === 0) {
        history.push(`/angket/${id_angket}/matakuliah`); 
        
      }
      // if (resp.data && resp.data.jawaban.length >= 3) {
      //   history.push("/angket"); 
      // }
      setData(resp.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id_angket]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSave}>
            <div className="card">
              <div className="card-header">
                <span>
                  <h3>
                    Silahkan memilih salah satu jawaban pada setiap nomor yang
                    menurut Anda sesuai, dan akhiri dengan menekan tombol simpan
                    dibawah soal ini{" "}
                  </h3>
                </span>
              </div>
              <div className="card-body mt-3">
                <h3>{data.dosen && data.dosen[0].nama_mk}</h3>
                
                <div className="basic-form">                
                  <div>
                    {data.angket && data.angket.multi > 0 ? (
                      <Form.Select aria-label="Nama Dosen" name="nidn" onChange={(e) => handleDosen(e)}>
                        <option value="">Silahkan pilih Dosen</option>
                        {data.dosen.map((item, key) => (
                          <option key={key} value={(item.kode_user.trim())}>{item.nama}</option>
                        ))}
                      </Form.Select>
                    ):''}
                  

                    <div className="table-responsive">
                      <table
                        id="example"
                        className="table display responsive nowrap w-100 dataTable dataTables_wrapper"
                        role="grid"
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr>
                            <th>Pernyataan</th>
                            {data.pilihan &&
                              data.pilihan.map((pilihan, pIndex) => (
                                <th key={pIndex}>
                                  {pilihan.nama_angket_pilihan_jawaban}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.base_table && data.base_table === 'angket' &&
                          data.soal && data.soal.map((row, index) => (
                            <tr
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#797777" : "white",
                              }}
                            >
                              <td>{row.nama_angket_soal}</td>
                              {row.pilihan.map((val, pIndex) => (
                                <td key={pIndex}>
                                  <input
                                    type="radio"
                                    name={`option-${row.id_angket_soal}`}
                                    value={val.id_angket_pilihan_jawaban}
                                    onChange={(e) => handleChange(e, index)}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))
                          }
                          {data.base_table && data.base_table === 'tbl_survey' &&
                          data.soal && data.soal.map((row, index) => (
                            <tr
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#797777" : "white",
                              }}
                            >
                              <td>{row.pertanyaan}</td>
                              {row.pilihan.map((val, pIndex) => (
                                <td key={pIndex}>
                                  <input
                                    type="radio"
                                    name={`option-${row.id}`}
                                    value={val.id_angket_pilihan_jawaban}
                                    onChange={(e) => handleChange(e, index)}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))
                          }
                          <tr>
                            <td colSpan={5}>
                              <p>Tuliskan saran Anda dibawah ini  (Wajib)</p>
                              
                              {/* <FormElements label='Saran (wajib)' type='textarea' name='saran' value={saran} onChange={(e)=>setSaran(e.target)} /> */}
                              <FormElementsVertical 
                                name={'saran'}
                                label={'Saran'}
                                type={'textarea'}
                                datasource={saran}                            
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Button type="submit">
                  <FontAwesomeIcon icon={faCheck} className="pe-2" />
                  Simpan
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AngketStartProfesi;
