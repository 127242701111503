import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS_MAHASISWA = [
	{
		Header : 'NAMA',
		accessor: 'nama',
		Filter: ColumnFilter,
		width:'40%'
	},
	{
		Header : 'NIM',
		accessor: 'nim',
		Filter: ColumnFilter,
		width:'30%'
	},
	{
		Header : 'Angkatan',
		accessor: 'tahun',
		Filter: ColumnFilter,
		width:'30%'
	},

]
export const COLUMNS_DOSEN = [
	{
		Header : 'NAMA',
		accessor: 'nama',
		Filter: ColumnFilter,
		width:'40%'
	},
	{
		Header : 'KODE REGISTRASI',
		accessor: 'kode_user',
		Filter: ColumnFilter,
		width:'30%'
	},
	{
		Header : 'STATUS',
		accessor: 'nama_dosen_klinik_status',
		Filter: ColumnFilter,
		width:'30%'
	},

]
export const COLUMNS_JADWAL = [
	{
		Header : 'Mata Kuliah/Department',
		accessor: 'nama_mk',
		Filter: ColumnFilter,
		width:'35%'
	},
	{
		Header : 'Kode',
		accessor: 'kode_mk',
		Filter: ColumnFilter,
		width:'10%'
	},
	{
		Header : 'Dosen',
		accessor: 'nama',
		Filter: ColumnFilter,
		width:'35%'
	},
	{
		Header : 'Awal',
		accessor: 'awal',
		Filter: ColumnFilter,
		width:'10%'
	},
	{
		Header : 'Akhir',
		accessor: 'akhir',
		Filter: ColumnFilter,
		width:'10%'
	},
]
export const COLUMNS_JADWAL_USER = [
	{
		Header : 'Mata Kuliah/Department',
		accessor: 'nama_mk',
		Filter: ColumnFilter,
		width:'35%'
	},
	{
		Header : 'Kode',
		accessor: 'kode_mk',
		Filter: ColumnFilter,
		width:'10%'
	},
	{
		Header : 'Jumlah Dosen',
		accessor: 'jumlah_dosen',
		Filter: ColumnFilter,
		width:'10%'
	},
	{
		Header : 'Awal',
		accessor: 'awal',
		Filter: ColumnFilter,
		width:'10%'
	},
	{
		Header : 'Akhir',
		accessor: 'akhir',
		Filter: ColumnFilter,
		width:'10%'
	},
]
export const COLUMNS_ANGKET_ADMIN = [
	{
		Header : 'Angket',
		accessor: 'nama_angket',
		Filter: ColumnFilter,
	},
	{
		Header : 'Kategori',
		accessor: 'kategori',
		Filter: ColumnFilter,
	},
	{
		Header : 'Jenjang',
		accessor: 'jenjang',
		Filter: ColumnFilter,
	},
	{
		Header : 'Awal',
		accessor: 'published_on',
		Filter: ColumnFilter,
	},
	{
		Header : 'Akhir',
		accessor: 'expired_on',
		Filter: ColumnFilter,
	},
]
export const COLUMNS_ANGKET_REKAP_MAHASISWA = [
	{
		Header : 'NIM',
		accessor: 'kode_user',
		Filter: ColumnFilter,
	},
	{
		Header : 'Nama',
		accessor: 'nama_user',
		Filter: ColumnFilter,
	},
	{
		Header : 'Mata Kuliah',
		accessor: 'nama_mk',
		Filter: ColumnFilter,
	},
	{
		Header : 'Dosen/Dokter',
		accessor: 'nama_dosen',
		Filter: ColumnFilter,
	},

]
