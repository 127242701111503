import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import DonutChart from './Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import AngketAdminDashboard from '../AngketAdmin/AngketAdminDashboard';

const Home = () => {
	const history = useHistory();
	const [userRole, setUserRole] = useState('');
	const [data,setData] = useState([]);
	const [user, setUser] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			const userDetails = JSON.parse(localStorage.getItem('userDetails'));
			if (userDetails && userDetails.role) {
				setUserRole(userDetails.role);
			} else {
				history.push('/login');
				return;
			}
			try {
				const resp = await UseAPI('angket', {}, 'GET');
				setData(resp.data);
				setUser(resp.data_user);
			} catch (error) {
				history.push('/login');
				return;
				// console.error("Error fetching data", error);
			}
		};
		fetchData();
	}, []);

	const cetak = async (id) => {	
		try {
			await UseAPI('angket/cetak/'+id, {}, 'GET');
		} catch (error) {
			console.error("Error fetching data", error);
		}
	}
	return(
		<>
			{
				user?.id_tipe_akun === 1 || user?.id_tipe_akun === 2 ? (
					<div className="row">
						{
							data.map((item,key) => (
								<div className="col-xl-4 col-lg-4" key={key}>
									<div className="row">
										<div className="col-xl-12">
										<div className="card">
											<div className="card-body my-3">	
												<div className="row">
													<div className="col-xl-12 col-xxl-12 col-md-12 col-sm-12">
														<div className="row">
															<div className="d-flex col-xl-12 col-xxl-12  col-md-12 col-12 mb-4">
																<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#AC39D4"/>
																</svg>
																<div>
																	<p className="fs-14 mb-2">{`${item?.nama_angket}`}</p>
																	{/* <span className="fs-16 font-w600 text-light"><span className="text-black me-2 font-w700">{item.published_on} s.d {item.expired_on}</span></span> */}
																	<p style={{fontSize:'2rem'}}>Periode : {item?.tahun_akademik_angket?.slice(0, 4)}-{item?.tahun_akademik_angket?.slice(-1)}</p>
																</div>
															</div>
														</div>
													</div>
												</div>							
											</div>
											<div className="card-footer border-0 pt-0">
												{item.kode_angket == 'angket_kepuasan_mahasiswa' ? (
												<div className='row'>
													<div className='col-6'>
														<Link to={"/angket/"+item.id_angket+"/matakuliah"} className="btn btn-primary d-block btn-lg text-uppercase">Lihat</Link>											
													</div>
													<div className='col-6'>
														<Link to="#" onClick={()=>cetak(item.id_angket)} className="btn btn-warning d-block btn-lg text-uppercase">Cetak</Link>											
													</div>
												</div>

												) : (
													<Link to={"/angket/"+item.id_angket} className="btn btn-primary d-block btn-lg text-uppercase">Lihat</Link>
												)}

											</div>
										</div>


										</div>
									</div>
								</div>				
							))
						}
					</div>
				)
				:
				(
					<>
						<AngketAdminDashboard />
					</>
				)
			}
		
		</>
	)
}
export default Home;
