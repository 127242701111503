export async function UseAPI(type, userData, method) {
    let BaseURL = 'https://apps.unismuh.ac.id/survey/';
    try {
        let requestOptions;
        switch (method) {            
            case 'POST':
                var formdata = new FormData();
                Object.entries(userData).forEach(([key, value]) =>
                    formdata.append(key, value)
                );                
                requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                };
                if (type !== 'login') {
                    requestOptions.headers = {
                        token: JSON.parse(localStorage['userDetails']).idToken,
                        sessiontime: JSON.parse(localStorage['userDetails']).waktu ?? ''
                    };
                }
                const postResponse = await fetch(BaseURL + type, requestOptions);
                const postResult = await postResponse.json();
                return postResult;
            
            case 'DELETE':
                var myHeaders = new Headers();
                myHeaders.append("Accept", "*/*");
                myHeaders.append("Accept-Language", "en,en-US;q=0.9");
                myHeaders.append("Access-Control-Request-Method", "DELETE");
                myHeaders.append("Connection", "keep-alive");
                myHeaders.append("Origin", "http://localhost:3000");
                myHeaders.append("Referer", "http://localhost:3000/");
                myHeaders.append("Sec-Fetch-Dest", "empty");
                myHeaders.append("Sec-Fetch-Mode", "cors");
                myHeaders.append("Sec-Fetch-Site", "cross-site");
                myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36");
                
                requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                if (type !== 'login') {
                    requestOptions.headers = {
                        token: JSON.parse(localStorage['userDetails']).idToken,
                        sessiontime: JSON.parse(localStorage['userDetails']).waktu ?? ''
                    };
                }

                const deleteResponse = await fetch(BaseURL + type, requestOptions);
                const deleteResult = await deleteResponse.json();
                return deleteResult;
            
            default:
                const defaultHeaders = {
                    token: JSON.parse(localStorage['userDetails']).idToken ?? '',
                    sessiontime: JSON.parse(localStorage['userDetails']).waktu ?? ''
                };
                const defaultResponse = await fetch(BaseURL + type, {
                    method: 'GET',
                    headers: defaultHeaders
                });

                const contentType = defaultResponse.headers.get('content-type');
                if(contentType && contentType.includes('application/pdf')){
                    const contentDisposition = defaultResponse.headers.get('content-disposition');
                    console.log(contentDisposition);
                    let filename = '';
                    const defaultResult = await defaultResponse.blob();
                    const url = window.URL.createObjectURL(defaultResult);
                    const link = document.createElement('a');
                    link.href = url;
                    const timestamp = new Date().toLocaleString('id-ID').replace(/[/:]/g, '-').replace(/\s/g, '_');
                    filename = `survey_${timestamp}`;
                    link.setAttribute('download', filename+'.pdf'); // Set the file name with timestamp
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    // return defaultResult;

                } else {
                    const defaultResult = await defaultResponse.json();
                    return defaultResult;
                }



                // const defaultResult = await defaultResponse.json();
                if (defaultResponse.message === 'Unauthorized') {
                    localStorage.clear();
                    window.location.href = '/login';
                }
        }
    } catch (error) {
        throw error;
    }
}
