import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import PageTitle from '../../../layouts/PageTitle';
import '../../../../global/filtering.css';
import { v4 as uuid } from 'uuid'
import { Button, Dropdown } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faDotCircle, faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import FormElementsVertical from '../../Forms/FormElementsVerical';
import { UseAPI } from '../../../../services/UseAPI';
import { COLUMNS_DOSEN } from '../../../../global/Columns';
import { Delete } from '../../../../services/Helper';

function DosenPage(props) {
	const [data, setData] = useState([]);
	const columns = useMemo(() => COLUMNS_DOSEN,[]);
	const [showTitle, setShowTitle] = useState(true);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const resp = await UseAPI('dosen',{},'GET');
				setData(resp['data']);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchData();
	}, []);


	useEffect(() => {
		if (props.title === 'false') {
			setShowTitle(false);
		}
	}, []);



	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {pageIndex} = state  

	return (
		<>	
		{showTitle && (
			<PageTitle activeMenu="Daftar" motherMenu="Dosen/Dokter" />
		)}	
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Dosen/Dokter</h4>
				<span>
					<div className="d-flex align-items-center justify-content-between">
						<Dropdown className="dropdown">
							<Dropdown.Toggle as="div" className="btn-link i-false">
								<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
								<Dropdown.Item as={Link} to={`dosen/tambah`} className="dropdown-item">Baru</Dropdown.Item>
								{/* <Dropdown.Item as={Link} to={`#`} className="dropdown-item"></Dropdown.Item> */}
							</Dropdown.Menu>
						</Dropdown>
					</div> 					
				</span>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
					<table {...getTableProps()} className="table display">
						<thead>
						{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
					                  <th>No.</th>
									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps()} style={{width:column.width}}>
											{column.render('Header')}
											{column.canFilter ? column.render('Filter') : null}
										</th>
									))}
									<th>Aksi</th>
								</tr>
						))}
						</thead> 
						<tbody {...getTableBodyProps()} className="" >
						
							{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
                    					<td>{row.index+1}</td>
										{row.cells.map((cell) => {
                                            return <td key={uuid()} {...cell.getCellProps()}>{cell.render('Cell')}</td>
										})}
										<td>
											<div className="d-flex align-items-center justify-content-between">
												<Dropdown className="dropdown">
													<Dropdown.Toggle as="div" className="btn-link i-false">
														<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</Dropdown.Toggle>
													<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
														<Dropdown.Item as={Link} to={`dosen/edit/${row.original.id}`} className="dropdown-item">Edit</Dropdown.Item>
														<Dropdown.Item as={Link} to={`#`} onClick={() => Delete('dosen',row.original.id)} className="dropdown-item">Hapus</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div> 												
										</td>										
									</tr>
								)
							})}
						</tbody>
					</table> 
					<div className="d-flex justify-content-between">
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{''}
						</span>
						<span className="table-index">
							Go to page : {' '}
							<input type="number" 
								className="ml-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
							/>
						</span>
					</div>
					<div className="text-center mb-3">	
						<div className="filter-pagination  mt-3">
							<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
							
							<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
							</button>
							<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
							</button>
							<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>

	);
}

export default DosenPage;
