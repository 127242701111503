import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UseAPI } from '../../../services/UseAPI';

const Home = () => {
	const history = useHistory();
	const [userRole, setUserRole] = useState('');
	const [data,setData] = useState([]);

	useEffect(() => {
		// const userDetails = JSON.parse(localStorage.getItem('userDetails'));
		// if (userDetails && userDetails.role) {
		// 	setUserRole(userDetails.role);
		// } else {
		// 	history.push('/login');
		// }
		UseAPI('angket',{},'GET').then((resp)=>{
			setData(resp.data);
			console.log("resp",resp);
		})

	}, []);

	return(
		<>
			<div className="row">
				{
					data.map((item,key) => (
						<div className="col-xl-4 col-lg-4" key={key}>
							<div className="row">
								<div className="col-xl-12">
								<div className="card">
									<div className="card-body my-3">	
										<div className="row">
											<div className="col-xl-8 col-xxl-12 col-md-12 col-sm-12">
												<div className="row">
													<div className="d-flex col-xl-12 col-xxl-12  col-md-12 col-12 mb-4">
														<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#AC39D4"/>
														</svg>
														<div>
															<p className="fs-14 mb-2">{`${item.nama_angket}`}</p>
															{/* <span className="fs-16 font-w600 text-light"><span className="text-black me-2 font-w700">{item.published_on} s.d {item.expired_on}</span></span> */}
															<p className="fs-14">Periode : {item.tahun_akademik_angket}</p>
														</div>
													</div>
												</div>
											</div>
											{/* <div className="col-xl-4  col-xxl-12 col-md-12 col-sm-12">
												<div className="row">
													<div className="col-sm-12 mb-4 col-xs-12">
														<div className="bg-gradient3 rounded text-center p-3">
															<div className="d-inline-block position-relative donut-chart-sale mb-3">
																<DonutChart value={5} backgroundColor="rgba(255, 255, 255,1)"
																	backgroundColor2= "rgba(255, 255, 255, 0.2)"
																/>
																<small className="text-white">{<i class="fa fa-check fa-2x" aria-hidden="true"></i>}</small>
															</div>
															<span className="fs-14 text-white d-block">{`2 / ${item.jml_mhs}`}</span>
														</div>
													</div>
												</div>
											</div> */}
										</div>							
									</div>
									<div className="card-footer border-0 pt-0">
										<Link to={"/angket/"+item.id_angket} className="btn btn-primary d-block btn-lg text-uppercase">Lihat</Link>
									</div>
								</div>


								</div>
							</div>
						</div>				
					))
				}
			</div>		
		</>
	)
}
export default Home;
