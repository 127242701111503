import Swal from "sweetalert2";
import { UseAPI } from "./UseAPI";
import { Redirect } from "react-router-dom";

export const Delete = async (table_name,id) => {
  Swal.fire({
    title: 'Anda yakin?',
    text: "Data yang dihapus tidak dapat dikembalikan",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya',
    cancelButtonText: 'Batal'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const resp = await UseAPI(`${table_name}/${id}`, '', 'DELETE');
        Swal.fire(
          resp.status,
          resp.pesan,
          resp.status
        ).then(() => {
          window.location.reload();
        });
      } catch (error) {
        Swal.fire(
          'Error',
          'Terjadi kesalahan saat menghapus data',
          'error'
        );
      }
    }
  });
}

