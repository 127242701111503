import React, { useState, useRef, useEffect } from 'react';
import Select from "react-select";
import JoditEditor from 'jodit-react';

const options = (props) => {
    const optiondata = [];
    props.datasource && props.datasource.masterdata.map((item) => {
        optiondata.push({ value: item.id, label: item.type_name })
    })

    return optiondata
};

const FormElementsVertical = (props) => {
    console.log("props",props);
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const [config, setConfig] = useState({
        placeholder: props.placeholder || '',        
        readonly: props.readOnly ? true : false,
        hidePoweredByJodit: true,
        toolbar: props.toolBar ? false : true,
        statusbar: false,
        safeMode: true,
        minHeight: props.minHeight ? props.minHeight : 200,
    });

    switch (props.type) {
        case 'select':
            return (
                <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">{props.label}</label>
                    <div className="col-sm-9">
                        <Select
                            name={`${props.name}`}
                            options={props.masterdata}
                            isMulti={props.isMulti}
                            defaultValue={props.value}
                        >
                        </Select>
                    </div>
                </div>
            )
        case 'textarea':
            return (
                <div className="mb-3 row">
                    <div className="col-sm-12">
                        <JoditEditor
                            ref={editor}
                            value={props?.value || content}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                            id={props.name}
                            name={props.name}
                        />
                    </div>
                </div>
            )
        default:
            return (
                <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">{props.label}</label>
                    <div className="col-sm-9">
                        <input
                            name={props.name}
                            type={props.type}
                            className="form-control"
                            placeholder={props.label}
                            onChange={props.onChange}
                            defaultValue={props.value}
                        />
                    </div>
                </div>
            )
    }
}

export default FormElementsVertical
