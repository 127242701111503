import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import PageTitle from '../../layouts/PageTitle';
import '../../../global/filtering.css';
import { v4 as uuid } from 'uuid';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faDotCircle, faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import FormElementsVertical from '../Forms/FormElementsVerical';
import { UseAPI } from '../../../services/UseAPI';
import { COLUMNS_ANGKET_ADMIN } from '../../../global/Columns';
import { Delete } from '../../../services/Helper';

function AngketAdmin(props) {
  const [data, setData] = useState([]);
  const columns = useMemo(() => COLUMNS_ANGKET_ADMIN, []);
  const [showTitle, setShowTitle] = useState(true);
  const [showModalClone, setshowModalClone] = useState(false);
  const [cloneId, setCloneId] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      UseAPI('angket', {}, 'GET').then((resp) => {
		resp.data.map((angket) => {
			[...options].push({ value: angket.id_angket, label: angket.nama_angket})
		})
        setData(resp['data']);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (props.title === 'false') {
      setShowTitle(false);
    }
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex } = state;

  const handleClose = () => setshowModalClone(false);
  const handleShow = (id) => {
    setCloneId(id);
    setshowModalClone(true);
  };

  const handleClone = async () => {
    // Implement the clone functionality here using the cloneId
    console.log(`Cloning item with id: ${cloneId}`);
    handleClose();
  };

  return (
    <>
      {showTitle && <PageTitle activeMenu="Daftar" motherMenu="Angket" />}
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Angket</h4>
          <span>
            <div className="d-flex align-items-center justify-content-between">
              <Dropdown className="dropdown">
                <Dropdown.Toggle as="div" className="btn-link i-false">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                    </g>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item
                    as={Link}
                    to={`angket_admin/tambah`}
                    className="dropdown-item"
                  >
                    Baru
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </span>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table {...getTableProps()} className="table display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th>No.</th>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{ width: column.width }}
                      >
                        {column.render('Header')}
                        {column.canFilter ? column.render('Filter') : null}
                      </th>
                    ))}
                    <th>Aksi</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td>{row.index + 1}</td>
                      {row.cells.map((cell) => {
                        if (cell.column.Header === 'Soal') {
                          return (
                            <td key={uuid()} {...cell.getCellProps()}>
                              <FormElementsVertical
                                value={cell.value}
                                type="textarea"
                                readOnly="true"
                                toolBar="false"
                                minHeight="50"
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td key={uuid()} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                      })}
                      <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle
                              as="div"
                              className="btn-link i-false"
                            >
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x="0" y="0" width="24" height="24"></rect>
                                  <circle
                                    fill="#000000"
                                    cx="5"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                  <circle
                                    fill="#000000"
                                    cx="12"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                  <circle
                                    fill="#000000"
                                    cx="19"
                                    cy="12"
                                    r="2"
                                  ></circle>
                                </g>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                              <Dropdown.Item
                                as={Link}
                                to={`angket/edit/${row.original.id_angket}`}
                                className="dropdown-item"
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to={`#`}
                                onClick={() => handleShow(row.original.id_angket)}
                                className="dropdown-item"
                              >
                                Clone
                              </Dropdown.Item>
                              {/* <Dropdown.Item as={Link} to={`master/angket/clone`} onClick={() => Delete('angket_admin',row.original.id)} className="dropdown-item">Hapus</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
              <span className="table-index">
                Go to page :{' '}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {'<<'}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalClone} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Clone Angket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
		  <FormElementsVertical
			type="select"
			masterdata={data}
			readOnly="true"
			toolBar="false"
			minHeight="50"
			label="Nama Angket"
		  />
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClone}>
            Clone
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AngketAdmin;
